import React, { useCallback, useEffect } from 'react';
import Layout from '../components/Layout';

import WindowProvider from '../components/WinowProvider';
import SEO from '../components/Seo';
import { config } from '../../archive.config';
import { archiveItemType } from '../containers/History/HistoryLine/interface';

import './index.scss';
import './archive.scss';
import { Link } from 'gatsby';

const ArchivePage = (data: any) => {
  const Article = useCallback((props: { data: archiveItemType }) => {
    const {
      key,
      title,
      content,
      recommendation,
      author = [],
    } = props.data;
    const RenderAuthors = useCallback(() => {
      if (author.length > 0) {
        return (
          <>
            {author.map((item, index) => {
              if (index === author.length - 1) {
                if (item.link) {
                  return <a key={index} href={item.link} target={"_blank"}>{item.name || 'Unknown'}</a>;
                } else {
                  return <p key={index}>{item.name || 'Unknown'}</p>;
                }
              } else {
                if (item.link) {
                  return (
                    <div key={index} style={{ marginRight: '8px' }}>
                      <a href={item.link} target={"_blank"}>{item.name || 'Unknown'}</a> and
                    </div>
                  );
                } else {
                  return (
                    <div key={index} style={{ marginRight: '8px' }}>
                      <p>{item.name || 'Unknown'}</p> and{' '}
                    </div>
                  );
                }
              }
            })}
          </>
        );
      } else {
        return <p>Unknown</p>;
      }
    }, [author]);
    return (
      <article className="articleItem">
        <Link className="title" to={`/detail/${key}`}>
          {title}
        </Link>
        <div className="content">{content}</div>
        <footer className="footer">
          <div className="author">
            <RenderAuthors />
          </div>
          <div className="divider"></div>
          <div className="progress">
            <div
              className="percent"
              style={{ width: `${recommendation}%` }}
            ></div>
          </div>
        </footer>
      </article>
    );
  }, []);
  return (
    <WindowProvider>
      <SEO>
        <Layout>
          <div className="archive">
            <div className="topTitle">
              <div>AW's Historical Archive</div>
            </div>
            {/*<ul className="chapter">*/}
            {/*  <li>Chapter 1 - Autonomous Worlds Category 1</li>*/}
            {/*</ul>*/}
            <main className="articleContainer">
              <div className="left">
                <div className="title">AW Fundamentals</div>
                <div className="articleList">
                  {config
                    .sort((a, b) => {
                      return b.recommendation - a.recommendation;
                    })
                    .map((item, index) => (
                      <Article key={`${item.key + index}`} data={item} />
                    ))}
                </div>
              </div>
              {/*<div className="intro">*/}
              {/*  <div className="title">Introduction</div>*/}
              {/*  <div className="text">*/}
              {/*    The article argues for the concept of Autonomous Worlds, which*/}
              {/*    are worlds created using blockchain technology that have hard*/}
              {/*    diegetic boundaries and formalized introduction rules. These*/}
              {/*    worlds are maximally autonomous and can be nearly on par with*/}
              {/*    systems like the English language or physics itself. The*/}
              {/*    article also discusses the concept of interobjective*/}
              {/*    realities, which are shared intangible concepts that can be*/}
              {/*    made more objective and transparent using blockchain*/}
              {/*    technology. Autonomous Worlds can become the digital table we*/}
              {/*    sit around, the container in which we can begin to fashion a*/}
              {/*    new form of communal sense.*/}
              {/*  </div>*/}
              {/*</div>*/}
            </main>
          </div>
        </Layout>
      </SEO>
    </WindowProvider>
  );
};

export default ArchivePage;
